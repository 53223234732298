import Image from "next/image";
import Link from "next/link";
import { Button, Container } from "react-bootstrap";
import footerStyle from "./footer.module.css";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function NavBar() {
  return (
    <footer className="bg-white">
      <Container
        fluid
        className="py-4 d-flex justify-content-md-evenly flex-wrap flex-column flex-md-row"
      >
        {/* <Container fluid className='px-5 py-4 d-flex w-100'> */}
        {/* <Row className='px-5'>
          <Col> */}
        <ul className={`m-0 p-0 ${footerStyle.footerList}`}>
          <li className="mb-2">
            <Link
              href="#"
              className="text-decoration-none text-dark fw-semibold"
            >
              Our Recent Articles
            </Link>
          </li>

          <li>
            <a
              href="https://www.smartguy.com/article/mind-matters--the-chiropractic-approach-to-personal-growth"
              target="_blank"
              rel="noopener noreferrer"
              className={`text-decoration-none text-dark ${footerStyle.footerLink}`}
            >
              Mind Matters- The Chiropractic Approach to Personal Growth
            </a>
          </li>

          <li>
            <a
              href="https://www.smartguy.com/article/mind-balance---100-innovative-ways-it-can-unleash-your-full-potential"
              target="_blank"
              rel="noopener noreferrer"
              className={`text-decoration-none text-dark ${footerStyle.footerLink}`}
            >
              Mind Balance - 100 Innovative Ways it Can Unleash Your Full
              Potential
            </a>
          </li>

          <li>
            <a
              href="https://www.smartguy.com/article/mindbalance-not-only-helps-individuals-but-couples-stay-together-longer"
              target="_blank"
              rel="noopener noreferrer"
              className={`text-decoration-none text-dark ${footerStyle.footerLink}`}
            >
              MindBalance not only helps individuals but couples stay together
              longer
            </a>
          </li>

          <li className="mt-2">
            <a
              href="https://www.smartguy.com/articles"
              target="_blank"
              rel="noopener noreferrer"
              className={`text-decoration-none text-dark ${footerStyle.footerLink}`}
            >
              See MORE On SmartGuy
            </a>
          </li>
        </ul>

        <ul className={`m-0 p-0 ${footerStyle.footerList}`}>
          <li className="mb-2">
            <Link
              href="#"
              className="text-decoration-none text-dark fw-semibold"
            >
              About Us
            </Link>
          </li>

          <li>
            <Link
              href="/pages/our-story"
              className={`text-decoration-none text-dark ${footerStyle.footerLink}`}
            >
              Our Story
            </Link>
          </li>

          <li>
            <Link
              href="/pages/how-mindbalance-works"
              className={`text-decoration-none text-dark ${footerStyle.footerLink}`}
            >
              How MindBalance Works
            </Link>
          </li>

          <li>
            <Link
              href="/pages/the-first-step"
              className={`text-decoration-none text-dark ${footerStyle.footerLink}`}
            >
              The First Step
            </Link>
          </li>

          <li>
            <Link
              href="/pages/partnerships"
              className={`text-decoration-none text-dark ${footerStyle.footerLink}`}
            >
              Partnerships
            </Link>
          </li>

          <li>
            <Link
              href="/pages/press-and-media"
              className={`text-decoration-none text-dark ${footerStyle.footerLink}`}
            >
              Press and Media
            </Link>
          </li>
        </ul>

        <ul className={`m-0 p-0 ${footerStyle.footerList}`}>
          <li className="mb-2">
            <Link
              href="#"
              className="text-decoration-none text-dark fw-semibold"
            >
              Support
            </Link>
          </li>

          <li>
            <Link
              href="/contact-us"
              className={`text-decoration-none text-dark ${footerStyle.footerLink}`}
            >
              Contact Us
            </Link>
          </li>

          <li>
            <Link
              href="/faqs"
              target="_blank"
              className={`text-decoration-none text-dark ${footerStyle.footerLink}`}
            >
              FAQs
            </Link>
          </li>

          <li>
            <Link
              href="/pages/testimonials"
              target="_blank"
              className={`text-decoration-none text-dark ${footerStyle.footerLink}`}
            >
              {/* TESTIMONIALS */}
              Testimonials
            </Link>
          </li>

          <li>
            <Link
              href={"/professional-info"}
              className={`text-decoration-none text-dark ${footerStyle.footerLink}`}
            >
              Advertise
            </Link>
          </li>

          <li>
            <Link
              href={"/pages/affiliates"}
              className={`text-decoration-none text-dark ${footerStyle.footerLink}`}
            >
              Affiliates
            </Link>
          </li>
        </ul>

        <ul className={`m-0 p-0 ${footerStyle.footerList}`}>
          <li className="mb-">
            <Link
              href="#"
              className="text-decoration-none text-dark fw-semibold"
            >
              Follow us
            </Link>
          </li>

          <li className="mt-2">
            <a
              href={"https://www.facebook.com/MindBalanceNet1/"}
              target="_blank"
              rel="noopener noreferrer"
              className={`${footerStyle.footerSocialIcon}`}
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>

            <a
              href={"https://www.instagram.com/mind_balancenet/"}
              target="_blank"
              rel="noopener noreferrer"
              className={`${footerStyle.footerSocialIcon}`}
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>

            <a
              href={"https://twitter.com/MindBalanceNet"}
              target="_blank"
              rel="noopener noreferrer"
              className={`${footerStyle.footerSocialIcon}`}
              aria-label="Twitter"
            >
              <FaXTwitter />
            </a>

            <a
              href={"https://www.youtube.com/@MindBalanceNet"}
              target="_blank"
              rel="noopener noreferrer"
              className={`${footerStyle.footerSocialIcon}`}
              aria-label="YouTube"
            >
              <FaYoutube />
            </a>

            <a
              href={"https://www.tiktok.com/@mindbalancenet"}
              target="_blank"
              rel="noopener noreferrer"
              className={`${footerStyle.footerSocialIcon}`}
              aria-label="TikTok"
            >
              <FaTiktok />
            </a>

            <a
              href={"https://www.linkedin.com/in/mindbalancenet/"}
              target="_blank"
              rel="noopener noreferrer"
              className={`${footerStyle.footerSocialIcon}`}
              aria-label="LinkedIn"
            >
              <FaLinkedinIn />
            </a>
          </li>

          <li className="mb-2 mt-3">
            <Link
              href="#"
              className="text-decoration-none text-dark fw-semibold"
            >
              Get the App
            </Link>
          </li>

          <li>
            <a
              href="https://apps.apple.com/app/mind-balance/id6502398464"
              target="_blank"
              rel="noopener noreferrer"
              variant="dark"
              className="p-0 m-0 me-2"
            >
              <Image
                src="/app_store_apple.png"
                width={120}
                height={40}
                alt="Picture of the author"
              />
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=net.mind_balance&hl=en"
              target="_blank"
              rel="noopener noreferrer"
              variant="dark"
              className="p-0 m-0 me-2"
            >
              <Image
                src="/play-store_google.png"
                width={120}
                height={40}
                alt="Picture of the author"
              />
            </a>
          </li>
        </ul>
        {/* </Col>

        </Row> */}
      </Container>
      <div
        className={`text-center text-white p-2 ${footerStyle.footerBottom} ${footerStyle.footerLink}`}
      >
        Mind Balance - All rights reserved |
        <Link
          target="_blank"
          href={"/pages/terms-of-use"}
          className="text-white mx-1 text-decoration-none"
        >
          Terms & Conditions
        </Link>
        -
        <Link
          target="_blank"
          href={"/pages/privacy-policy"}
          className="text-white mx-1 text-decoration-none"
        >
          Privacy Policy
        </Link>
      </div>
    </footer>
  );
}
