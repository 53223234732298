export async function fetchTestimonial(accessToken) {

  let data = [];
  try {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}testimonials/list`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (res.ok) {
      data = await res.json();
    } else {
      // console.error("Failed to fetch trending:", res.statusText);
    }
  } catch (error) {
    // console.error("Error fetching trending:", error);
  }

  return data;
};