"use client";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Link from "next/link";
import Image from "next/image";
import Footer from "@components/Footer";
import Slider from "react-slick";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

import { useEffect, useState } from "react";
import { fetchTestimonial } from "@/actions/testimonials.actions";

import styles from "./styles.module.css";
import './carousel.css'


const Home = ({ accessToken, user }) => {

  const settings = {
    slickNext: true,
    slickPrev: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slickNext: true,
          slickPrev: true,
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 567,
        settings: {
          slickNext: true,
          slickPrev: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [loading, setLoading] = useState(true);
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const testimonials = await fetchTestimonial();
      if (testimonials && testimonials.data) {
        setTestimonials(testimonials.data)
      }
      setLoading(false)
    };

    if (loading) {
      fetchData();
    }
  }, [loading]);

  return (
    <section>
      <Container className={`${styles.container}`}>

        <section>
          <HeaderSection accessToken={accessToken} user={user} />
        </section>

        <section className="mt-5">
          <h2 className="text-center fs-5 fw-semibold mb-3">
            Unlock Your Potential with Mind Balance
          </h2>

          <div className="text-center d-flex justify-content-center">
            <p className="text-center text-muted mb-4" style={{ maxWidth: "860px" }}>
              Explore our powerful tools designed to help you grow and thrive.
              From personality insights to expert guidance and progress tracking, discover resources that
              empower your personal journey—completely free.
            </p>
          </div>

          <div className="d-flex justify-content-center mb-3">
            <Row style={{ maxWidth: "1080px" }}>
              <Col xs={{ span: 10, offset: 1 }} sm={{ span: 8, offset: 2 }} md={{ span: 4, offset: 0 }} lg={{ span: 4, offset: 0 }} className="mb-3">
                <Card className={`${styles.card}`}>
                  <Card.Header className="bg-transparent border-0">
                    <div className={`${styles.icon}`}>
                      <Image
                        width={29}
                        height={37}
                        src="/icons/clipboard.png"
                        className=""
                        alt="Clipboard"
                      />
                    </div>
                  </Card.Header>

                  <Card.Body className="d-flex align-items-center justify-content-center flex-column text-center pb-0 mb-0">
                    <h5 className="fs-5 fw-normal">Personality Tests</h5>
                    <p>
                      Uncover your strengths and unlock personal insights with
                      our scientifically backed personality tests. Know yourself
                      better and take the first step toward growth.
                    </p>
                  </Card.Body>
                  <Card.Footer className="border-0 bg-transparent">
                    <Link
                      href={'/assessment/self'}
                      // variant="warning"
                      className={`${styles.btnWarning} text-white btn btn-warning py-2`}
                    >
                      Start Your Test
                    </Link>
                  </Card.Footer>
                </Card>
              </Col>

              <Col xs={{ span: 10, offset: 1 }} sm={{ span: 8, offset: 2 }} md={{ span: 4, offset: 0 }} lg={{ span: 4, offset: 0 }} className="mb-3">
                <Card className={`${styles.card} mb-3`}>
                  <Card.Header className="bg-transparent border-0">
                    <div className={`${styles.icon}`}>
                      <Image
                        width={42}
                        height={28}
                        src="/icons/youtube.svg"
                        className=""
                        alt="Youtube"
                      />
                    </div>
                  </Card.Header>

                  <Card.Body className="d-flex align-items-center justify-content-center flex-column text-center pb-0">
                    <h5 className="fs-5 fw-normal">Self-Improvement Videos</h5>
                    <p>
                      Access a library of free self-improvement videos that
                      cover everything from mindset to productivity. Learn from
                      experts and boost your personal growth.
                    </p>
                  </Card.Body>
                  <Card.Footer className="border-0 bg-transparent">
                    <Link
                      href={'/assessment/advance-learning'}
                      className={`${styles.btnSuccess} btn btn-success py-2`}
                    >
                      Watch Now
                    </Link>

                  </Card.Footer>
                </Card>
              </Col>

              <Col xs={{ span: 10, offset: 1 }} sm={{ span: 8, offset: 2 }} md={{ span: 4, offset: 0 }} lg={{ span: 4, offset: 0 }} className="mb-3" >
                <Card className={`${styles.card}`}>
                  <Card.Header className="bg-transparent border-0">
                    <div className={`${styles.icon}`}>
                      <Image
                        width={42}
                        height={35}
                        src="/icons/trending.svg"
                        className=""
                        alt="Trending"
                      />
                    </div>
                  </Card.Header>

                  <Card.Body className="d-flex align-items-center justify-content-center flex-column text-center pb-0">
                    <h5 className="fs-5 fw-normal">Progress Tracking</h5>
                    <p>
                      Track your personal growth with intuitive, easy-to-use
                      tools. Monitor your progress and celebrate every milestone
                      on your journey.
                    </p>
                  </Card.Body>
                  <Card.Footer className="border-0 bg-transparent">
                    <Link
                      href={'/dashboard'}
                      // variant="warning"
                      className={`${styles.btnWarning} text-white btn btn-warning py-2`}
                    >
                      Start Tracking
                    </Link>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </div>
        </section>

        <section className="mt-5">
          <h2 className="text-center fs-3 fw-bold">
            Why Pay When You Can Grow for Free?
          </h2>
          <p className="text-center mt-3">
            Get unlimited access to premium self-growth resources without
            spending a penny.
          </p>

          <div className="d-flex justify-content-center mt-3">
            <Row className={`${styles.sectionThree}`}>
              <Col xs={12} md={6} lg={6} className="mb-3">
                <Card
                  className={`${styles.featureCard} ${styles.featureCardGreen} `}
                >
                  <Card.Body>
                    <ul className={`${styles.featureList}`}>
                      <li className={`${styles.positive}`}>Unlimited Access</li>
                      <li className={`${styles.positive}`}>Premium Content</li>
                      <li className={`${styles.positive}`}>
                        No Credit Card Required
                      </li>
                      <li className={`${styles.positive}`}>
                        Personalized Recommendations
                      </li>
                      <li className={`${styles.positive}`}>
                        Ad-Free Experience
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>

              <Col xs={12} md={6} lg={6}>
                <Card
                  className={`${styles.featureCard} ${styles.paymentCardWarning}`}
                >
                  <Card.Body>
                    <ul className={`${styles.featureList}`}>
                      <li className={`${styles.negative}`}>
                        {" "}
                        Limited by Subscription
                      </li>
                      <li className={`${styles.negative}`}>
                        Requires Paid Upgrade
                      </li>
                      <li className={`${styles.negative}`}>
                        Mandatory for Sign-Up
                      </li>
                      <li className={`${styles.positive}`}>
                        Only in Premium Plans
                      </li>
                      <li className={`${styles.negative}`}>Ad-Supported</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </section>


        <section className="mt-5 mb-5">
          <h2 className="text-center fs-3 fw-bold">Hear From Our Community</h2>
          <p className="text-center mt-3">
            Join thousands of people who’ve transformed their lives with Mind
            Balance. Here’s what they have to say
          </p>

          {loading &&
            <Row>
              {Array(3).fill().map((_, index) => (
                <Col xs={12} md={4} key={index}>
                  <Card className={`${styles.testimonialCard} shadow-sm `}>

                    <Skeleton inline={true} rounded width={"70%"} height={20} className="mb-3" />
                    <Skeleton inline={true} count={4.5} rounded width={"100%"} height={20} className="" />

                    <Card.Footer className="py-0 bg-transparent border-0 mt-3 px-0">
                      <div className="d-flex flex-start mt-3">
                        <div className="d-flex justify-content-center align-items-start align-items-center">
                          <Skeleton inline={true} rounded width={40} height={40} className="rounded-circle" />
                          <Skeleton inline={true} rounded width={120} height={20} className="ms-2" />
                        </div>
                      </div>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>}

          {testimonials && testimonials.length > 0 &&
            <div className="slider-container">
              <Slider {...settings}>
                {testimonials.map((testimonial, index) => (
                  <div className="d-flex px-2 h-100" key={index} >
                    <Card
                      className={`${styles.testimonialCard} shadow-sm `}
                      key={index}
                    >
                      <Card.Header className={`bg-transparent border-0`}>
                        <q className={`${styles.testimonialCardTitle}`}>{testimonial.title}</q>
                      </Card.Header>

                      <Card.Body className="py-1">
                        <Card.Text className={`${styles.testimonialCardDes}`}>
                          {testimonial.description}
                        </Card.Text>
                      </Card.Body>

                      <Card.Footer className="py-0 bg-transparent border-0">
                        <div className="d-flex flex-start mt-3">
                          <div className={`${styles.testimonialPic}`}>
                            {testimonial.image &&
                              <Image
                                src={`${process.env.NEXT_PUBLIC_S3_BUCKET_URL}${testimonial?.image}`}
                                width={75}
                                height={50}
                                alt="Profile Image"
                              />
                            }
                          </div>

                          <div className="d-flex justify-content-center align-items-start flex-column">
                            <Card.Title className={`${styles.testimonialName} mb-0`}>
                              {testimonial.name}
                            </Card.Title>
                            <Card.Text
                              style={{ fontSize: "0.85rem", color: "#6C757D" }}
                            >
                              {testimonial.age} ({testimonial.city})
                            </Card.Text>
                          </div>
                        </div>
                      </Card.Footer>
                    </Card>
                  </div>
                ))}
              </Slider>
            </div>
          }
        </section>

      </Container>
      <Footer />
    </section>
  );
};

const HeaderSection = ({ accessToken, user }) => {
  const heading = "Unlock Your True Potential For Free!";
  const description =
    "Discover scientifically-backed tools to boost your personal growth, completely free of charge.";

  return (
    <>
      {/* Desktop and Large Screens */}
      <div className="d-none d-lg-block">
        <Row
          className="justify-content-center align-items-center"
          style={{ minHeight: "600px" }}
        >
          <Col sm={6}>
            <div>
              <h1 className="fw-bold">{heading}</h1>
              <p style={{ maxWidth: "80%" }}>{description}</p>
            </div>
            <DashboardLinks accessToken={accessToken} user={user} />
          </Col>
          <Col sm={6} className="d-flex justify-content-center">
            <Image
              src="/images/brain_icon.svg"
              layout="responsive"
              width={100}
              height={100}
              alt="Home Image"
            />
          </Col>
        </Row>
      </div>

      {/* Medium Screens */}
      <div className="d-none d-md-block d-lg-none">
        <Row>
          <Col md={12} className="mt-5">
            <h1 className="fw-bold">Unlock Your True Potential For Free!</h1>
            <p style={{ maxWidth: "80%" }}>
              Discover scientifically-backed tools to boost your personal
              growth, completely free of charge.
            </p>
          </Col>
          <Col sm={6} md={4} className="mt-5">
            <DashboardLinks accessToken={accessToken} user={user} />
          </Col>
          <Col sm={6} md={8} className="d-flex-end">
            <Image
              layout="responsive"
              width="100"
              height="100"
              src="/images/brain_icon.svg"
              className="ps-2 pe-2 pe-md-3 mt-1"
              alt="Home image"
            />
          </Col>
        </Row>
      </div>

      {/* Small Screens */}
      <div className="d-md-none">
        <div className="text-center mt-5">
          <h1 className="fw-bold">{heading}</h1>
          <p>{description}</p>
        </div>
        <Row className="justify-content-center align-items-center">
          <Col sm={12} className="d-flex justify-content-center">
            <Image
              layout="responsive"
              src="/images/brain_icon.svg"
              width={80}
              height={80}
              alt="Home Image"
            />
          </Col>
          <Col sm={12} className="mt-2">
            <DashboardLinks accessToken={accessToken} user={user} />
          </Col>
        </Row>
      </div>
    </>
  );
};

const DashboardLinks = ({ accessToken, user }) => {
  if (accessToken && user) {
    return (
      <div className="text-start">
        <Link
          href={
            user.role === "admin"
              ? "/admin/dashboard"
              : user.role === "professional"
                ? "/professional/dashboard"
                : "/dashboard"
          }
          className="btn btn-success mt-2 text-uppercase"
        >
          Go To My Dashboard
        </Link>
      </div>
    );
  }
  return (
    <div className={`${styles.buttonsFlex}`}>
      <div>
        <Link href="/signup" className="btn btn-success px-4 mt-2 me-2 py-2">
          Join For Free!
        </Link>
      </div>
      <div>
        <Link href="/login" className="btn btn-warning px-2 text-white mt-2 py-2">
          Already Have an Account
        </Link>
      </div>
    </div>
  );
};

export default Home;
